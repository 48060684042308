import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default function createStore() {
	return new Vuex.Store({
		state: {
			host: '',
			deviceType: 'mobile',
			adsensConfig: {
				scriptUrl: 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-8158555231596181',
				ad1: {
					"class": "adsbygoogle",
					"style": "display:block",
					"data-ad-client": "ca-pub-8158555231596181",
					"data-ad-slot": "6955171686",
					"data-ad-format": "auto",
					"data-full-width-responsive": "true"
				},
				ad2: {
					"class": "adsbygoogle",
					"style": "display:block",
					"data-ad-client": "ca-pub-8158555231596181",
					"data-ad-slot": "3015926678",
					"data-ad-format": "auto",
					"data-full-width-responsive": "true"
				},
				ad3: {
					"class": "adsbygoogle",
					"style": "display:block",
					"data-ad-client": "ca-pub-8158555231596181",
					"data-ad-slot": "6252464109",
					"data-ad-format": "auto",
					"data-full-width-responsive": "true"
				},
				ad4: {
					"class": "adsbygoogle",
					"style": "display:block",
					"data-ad-client": "ca-pub-8158555231596181",
					"data-ad-slot": "8486140542",
					"data-ad-format": "auto",
					"data-full-width-responsive": "true"
				},
				ad5: {
					"class": "adsbygoogle",
					"style": "display:block",
					"data-ad-client": "ca-pub-8158555231596181",
					"data-ad-slot": "4939382434",
					"data-ad-format": "auto",
					"data-full-width-responsive": "true"
				},
				ad6: {
					"class": "adsbygoogle",
					"style": "display:block",
					"data-ad-client": "ca-pub-8158555231596181",
					"data-ad-slot": "6929516295",
					"data-ad-format": "auto",
					"data-full-width-responsive": "true"
				}
			}
			,
			showDebug: false,
			firebaseConfig: {
				apiKey: "AIzaSyBcS3cwlUXpK99s0FiNLcdhiTqTbqa8pRo",
				authDomain: "webs-58a8d.firebaseapp.com",
				projectId: "webs-58a8d",
				storageBucket: "webs-58a8d.appspot.com",
				messagingSenderId: "730684174767",
				appId: "1:730684174767:web:3ddb46fb6662c5060c3f5a",
				measurementId: "G-8PF8VM06BY"
			}
		},
		mutations: {
			setHost(state, host) {
				state.host = host
			},
			setTitle(state, title) {
				state.title = title
			},
			setDomain(state, domainConfig) {
				state.domainConfig = domainConfig
			},
			setDeviceType(state, deviceType) {
				state.deviceType = deviceType
			},
			setDebug(state, showDebug) {
				state.showDebug = showDebug
			},
		},
		getters: {
			showDebug: (state) => state.showDebug,
			deviceType: (state) => state.deviceType,
			host: (state) => state.host,
			adsensConfig: (state) => state.adsensConfig,
		},
	})
}
